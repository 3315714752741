import { Component } from '@angular/core'

@Component({
    selector: 'wls-notice',
    template: `
        <div class="row mt-3">
            <small [style.font-size]="'8px'" class="col-12 text-center">
                This software is created by <a target="_blank" href="https://woodl.nl/">Woodland Software B.V.</a>
            </small>
            <small [style.font-size]="'8px'" class="col-12 text-center">
                This site is protected by reCAPTCHA and the Google
                <a target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.
            </small>
        </div>
    `,
    standalone: true,
})
export class WlsNoticeComponent {}
